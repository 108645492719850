@import './../../variables.scss';

header {
    text-align: center;

    h1 {
        text-shadow: 3px 3px $color-3;
    }

    h3 {
        color: $color-3;
    }
}