@import "./../../variables.scss";

#header {
    flex-direction: row;
    margin: 0px;
    min-height: 100vh;

    header {
        text-align: center;
        flex-basis: 620px;
        flex-grow: 1;
    
        h1.title {
            font-size: 150px;
            text-shadow: 5px 5px $color-black,9px 9px $color-3;
            color: $color-white;
        }
    
        h2 {
            color: $color-3;
        }
    }
    
    .img {
        flex-basis: 620px;
        flex-grow: 1;
        align-self: stretch;
        background: url('./../../assets/img/img5-adjusted-ls.jpg') no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

@media screen and (max-width: 1240px) {
    #header {
        header {
            h1.title {
                margin-top: 75px;
                font-size: 80px;
            }
    
            h2 {
                margin-top: -10px;
            }
        }

        .img {
            align-self: flex-start;
            height: 750px;
        }
    }
}