/* You can add global styles to this file, and also import other style files */

@import "./../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "./variables.scss";

html, body, #root, #app {
    margin: 0px;
    width: 100%;
    font-family: PT Sans, sans-serif;
    background-color: $color-black;
    color: $color-white;
}

h1 {
    margin: 0px;
    font-size: 80px;
    font-family: Poppins, sans-serif;
    margin-bottom: -10px;
}

h2 {
    margin: 0px;
    font-size: 40px;
    font-family: Oswald, sans-serif;
}

h3 {
    font-size: 30px;
    margin: 0px;
    font-family: Oswald, sans-serif;
}

h4 {
    font-size: 20px;
    margin: 0px;
    font-family: PT Sans, sans-serif;
}

a {
    text-decoration: none;

    &:visited {
        color: $color-white;
    }

    &:focus {
        color: $color-white;
    }

    &:link {
        color: $color-white;
    }
}

section {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
}

button {
    border: none;
    background: none;
    font-family: Oswald, sans-serif;
    font-size: 30px;

    &.hoverClass, &:focus {
        outline: none;
    }
}

.reveal {
    display: none;
}

.animate-2 {
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.animate-3 {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.animate-5 {
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.hidden {
    display: none;
}

@media screen and (max-width: 1140px) {
    h1 {
        margin-top: 75px;
        font-size: 50px;
    }
}

@media screen and (max-width: 650px) {
    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 25px;
    }

    h3 {
        font-size: 25px;
    }
}