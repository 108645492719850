@import url("https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans");

$color-1: #fff8dc;
$color-2: #ffdead;
$color-3: #deb887;
$color-4: #d2691e;
$color-5: #a0522d;
$color-6: #800000;

$color-7: #800000;
$color-8: #008000;
$color-9: #000080;

$color-black: #000000;
$color-white: #ffffff;