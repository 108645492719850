@import "./../../variables.scss";

#sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: $color-black;
    overflow-x: hidden;
    padding-top: 100px;
    text-align: right;

    a {
        text-decoration: none;
        font-size: 40px;
        display: block;
        font-family: Oswald, sans-serif;
        padding: 10px 40px;
        color: $color-white;

        &:hover {
            text-shadow: 3px 3px $color-3;
        }
    }

    &.opened {
        width: 300px;
    }
}

@media screen and (max-width: 500px) {
    #sidenav.opened {
        width: 100%;
    }
}