@import './../../variables.scss';

#career {
    height: 100vh;
    flex-flow: row nowrap;

    div {
        position: relative;
        height: 100%;
        flex: 1 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            background-color: rgba(0,0,0,0.5);
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            opacity: 0;

            h2 {
                text-shadow: 3px 3px $color-3;
            }

            p {
                margin: 0px;
            }
        }

        &.hoverClass {
            flex-basis: 250px;

            .overlay {
                opacity: 1;
            }
        }
    }
}