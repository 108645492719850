@import './../../variables.scss';

.logo {
    background: none;
    text-decoration: none;
    position: fixed;
    z-index: 2;
    top: 0px;
    left: 0px;
    padding: 30px 40px;
    text-align: center;
    font-family: Oswald, sans-serif;
    font-size: 30px;
    color: white;
    transition: 0.3s;
  
    &:hover {
        color: $color-3;
    }
}

@media screen and (max-width: 650px) {
    .logo {
        font-size: 25px;
        padding: 37px 40px;
    }
  }