@import './../../variables.scss';

#locations {
    min-height: 100vh;

    header {
        text-align: center;
        margin: 30px 0px;

        h1 {
            color: $color-white;
            text-shadow: 3px 3px $color-3;
        }

        h3 {
            color: $color-3;
        }
    }

    .location-catalogue {
        width: 100%;
        flex: 1 0;
        display: flex;
        flex-flow: row wrap;

        .location {
            flex: 1 0 300px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            position: relative;

            .overlay {
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                background-color: rgba(0,0,0,0.5);
                opacity: 0;
            }

            h2 {
                color: $color-6;
                font-size: 60px;
            }

            p {
                margin: 0px;
            }

            &.school {
                background: url('./../../assets/img/Centrum\ voor\ de\ kunsten.jpg') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;

                h2 {
                    color: $color-white;
                }

                p {
                    color: $color-3;
                    text-align: center;
                }
            }

            &.at-home {
                background: url('./../../assets/img/road.jpg') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }

            &.hoverClass {
                .overlay {
                    opacity: 1;
                }

                h2 {
                    text-shadow: 4px 4px $color-3;
                    z-index: 2;
                }

                p {
                    z-index: 2;
                }
            }
        }
    }
}