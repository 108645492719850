@import './../../variables.scss';

#bio {
    min-height: 100vh;
    background-color: $color-black;
    justify-content: space-around;
    overflow: hidden;
    flex-wrap: nowrap;

    header {
        text-align: center;

        h1 {
            text-shadow: 3px 3px $color-3;
        }

        h3 {
            color: $color-3;
        }
    }

    .img-container {
        width: 30vh;
        height: 30vh;
        border-radius: 50%;
        overflow-y: hidden;
        box-shadow: 3px 3px $color-black, 6px 6px $color-3;

        img {
            max-width: 100%;
            object-fit: cover;
        }
    }

    p {
        padding: 0px 15%;
        text-align: center;
        margin: 20px 0px;

        span {
            font-weight: bold;
            font-size: 18px;
            color: $color-3;
        }
    }
}