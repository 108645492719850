@import "./../../variables.scss";

#sidenav-btn {
  position: fixed;
  z-index: 3;
  top: 0px;
  right: 0px;
  padding: 25px 40px;
  text-align: center;
  font-size: 40px;
  color: $color-white;
  

  &:hover {
    text-shadow: 3px 3px $color-3;
  }

  &:focus {
    outline: none;
  }
}