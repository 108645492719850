@import './../../variables.scss';

#contact {
    min-height: 100vh;
    margin: 0px;

    header {
        text-align: center;
        margin: 100px 0px;

        h2 {
            color: $color-white;
        }

        p {
            margin: 0px;
        }
    }

    .contact-options {
        width: 100%;
        display: flex;
        flex-flow: row wrap;

        div {
            flex: 1 0 300px;
            text-align: center;
            margin: 20px;

            i {
                color: $color-white;
                font-size: 100px;
                margin-bottom: 35px;
                text-shadow: 4px 4px $color-3;
            }

            h3 {
                color: $color-3;
            }
        }
    }
}