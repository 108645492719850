@import './../../variables.scss';

.auto-scroll-btn {
    position: absolute;
    width: 100%;
    bottom: 0px;
    text-align: center;
    margin-bottom: 10px;
    
    i {
      font-size: 50px;
      color: $color-white;
      transition: 0.3s;
      text-shadow: 3px 3px $color-3;
      
      &.hoverClass {
        text-shadow: 3px 3px $color-3;
      }
    }
  }

@media screen and (max-width: 650px) {
  .auto-scroll-btn {
    i {
      font-size: 30px;
    }
  }
}