@import './../../variables.scss';

#lessons {
    background-color: $color-6;
    min-height: 100vh;

    header {
        text-align: center;
        margin: 30px 0px;

        h1 {
            color: $color-white;
            text-shadow: 3px 3px $color-3;
        }

        h3 {
            color: $color-3;
        }
    }

    .aspect-catalogue {
        margin-top: 50px;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;



        div {
            padding: 30px 0px;
            flex: 1 0 300px;
            text-align: center;

            h3 {
                color: $color-3;
            }

            i {
                font-size: 100px;
                margin-bottom: 20px;
            }

            p {
                color: $color-white;
                word-wrap: break-word;
                padding: 0px 30px;
            }

            // &.hoverClass {
            //     background-color: $color-3;

            //     h3 {
            //         color: $color-6;
            //     }

            //     i {
            //         text-shadow: 2px 2px $color-3, 6px 6px $color-6;
            //     }

            //     p {
            //         color: $color-6;
            //     }
            // }

            &.pupil1 {
                background: url('./../../assets/img/pupil1.png') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }

            &.pupil2 {
                background: url('./../../assets/img/pupil2.png') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }

            &.pupil3 {
                background: url('./../../assets/img/pupil3.png') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }

            &.pupil4 {
                background: url('./../../assets/img/pupil4.png') no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }
    }
}