@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Oswald|PT+Sans);
/* You can add global styles to this file, and also import other style files */
html, body, #root, #app {
  margin: 0px;
  width: 100%;
  font-family: PT Sans, sans-serif;
  background-color: #000000;
  color: #ffffff; }

h1 {
  margin: 0px;
  font-size: 80px;
  font-family: Poppins, sans-serif;
  margin-bottom: -10px; }

h2 {
  margin: 0px;
  font-size: 40px;
  font-family: Oswald, sans-serif; }

h3 {
  font-size: 30px;
  margin: 0px;
  font-family: Oswald, sans-serif; }

h4 {
  font-size: 20px;
  margin: 0px;
  font-family: PT Sans, sans-serif; }

a {
  text-decoration: none; }
  a:visited {
    color: #ffffff; }
  a:focus {
    color: #ffffff; }
  a:link {
    color: #ffffff; }

section {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center; }

button {
  border: none;
  background: none;
  font-family: Oswald, sans-serif;
  font-size: 30px; }
  button.hoverClass, button:focus {
    outline: none; }

.reveal {
  display: none; }

.animate-2 {
  transition: 0.2s; }

.animate-3 {
  transition: 0.3s; }

.animate-5 {
  transition: 0.5s; }

.hidden {
  display: none; }

@media screen and (max-width: 1140px) {
  h1 {
    margin-top: 75px;
    font-size: 50px; } }

@media screen and (max-width: 650px) {
  h1 {
    font-size: 40px; }
  h2 {
    font-size: 25px; }
  h3 {
    font-size: 25px; } }

#header {
  flex-direction: row;
  margin: 0px;
  min-height: 100vh; }
  #header header {
    text-align: center;
    flex-basis: 620px;
    flex-grow: 1; }
    #header header h1.title {
      font-size: 150px;
      text-shadow: 5px 5px #000000, 9px 9px #deb887;
      color: #ffffff; }
    #header header h2 {
      color: #deb887; }
  #header .img {
    flex-basis: 620px;
    flex-grow: 1;
    align-self: stretch;
    background: url(/static/media/img5-adjusted-ls.8f7a7e8f.jpg) no-repeat center center;
    background-size: cover; }

@media screen and (max-width: 1240px) {
  #header header h1.title {
    margin-top: 75px;
    font-size: 80px; }
  #header header h2 {
    margin-top: -10px; }
  #header .img {
    align-self: flex-start;
    height: 750px; } }

.auto-scroll-btn {
  position: absolute;
  width: 100%;
  bottom: 0px;
  text-align: center;
  margin-bottom: 10px; }
  .auto-scroll-btn i {
    font-size: 50px;
    color: #ffffff;
    transition: 0.3s;
    text-shadow: 3px 3px #deb887; }
    .auto-scroll-btn i.hoverClass {
      text-shadow: 3px 3px #deb887; }

@media screen and (max-width: 650px) {
  .auto-scroll-btn i {
    font-size: 30px; } }

#bio {
  min-height: 100vh;
  background-color: #000000;
  justify-content: space-around;
  overflow: hidden;
  flex-wrap: nowrap; }
  #bio header {
    text-align: center; }
    #bio header h1 {
      text-shadow: 3px 3px #deb887; }
    #bio header h3 {
      color: #deb887; }
  #bio .img-container {
    width: 30vh;
    height: 30vh;
    border-radius: 50%;
    overflow-y: hidden;
    box-shadow: 3px 3px #000000, 6px 6px #deb887; }
    #bio .img-container img {
      max-width: 100%;
      object-fit: cover; }
  #bio p {
    padding: 0px 15%;
    text-align: center;
    margin: 20px 0px; }
    #bio p span {
      font-weight: bold;
      font-size: 18px;
      color: #deb887; }

header {
  text-align: center; }
  header h1 {
    text-shadow: 3px 3px #deb887; }
  header h3 {
    color: #deb887; }

#career {
  height: 100vh;
  flex-flow: row nowrap; }
  #career div {
    position: relative;
    height: 100%;
    flex: 1 0; }
    #career div img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    #career div .overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      opacity: 0; }
      #career div .overlay h2 {
        text-shadow: 3px 3px #deb887; }
      #career div .overlay p {
        margin: 0px; }
    #career div.hoverClass {
      flex-basis: 250px; }
      #career div.hoverClass .overlay {
        opacity: 1; }

#lessons {
  background-color: #800000;
  min-height: 100vh; }
  #lessons header {
    text-align: center;
    margin: 30px 0px; }
    #lessons header h1 {
      color: #ffffff;
      text-shadow: 3px 3px #deb887; }
    #lessons header h3 {
      color: #deb887; }
  #lessons .aspect-catalogue {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
    #lessons .aspect-catalogue div {
      padding: 30px 0px;
      flex: 1 0 300px;
      text-align: center; }
      #lessons .aspect-catalogue div h3 {
        color: #deb887; }
      #lessons .aspect-catalogue div i {
        font-size: 100px;
        margin-bottom: 20px; }
      #lessons .aspect-catalogue div p {
        color: #ffffff;
        word-wrap: break-word;
        padding: 0px 30px; }
      #lessons .aspect-catalogue div.pupil1 {
        background: url(/static/media/pupil1.89083f10.png) no-repeat center center;
        background-size: cover; }
      #lessons .aspect-catalogue div.pupil2 {
        background: url(/static/media/pupil2.7c45b892.png) no-repeat center center;
        background-size: cover; }
      #lessons .aspect-catalogue div.pupil3 {
        background: url(/static/media/pupil3.42784a5a.png) no-repeat center center;
        background-size: cover; }
      #lessons .aspect-catalogue div.pupil4 {
        background: url(/static/media/pupil4.8fc556a1.png) no-repeat center center;
        background-size: cover; }

#locations {
  min-height: 100vh; }
  #locations header {
    text-align: center;
    margin: 30px 0px; }
    #locations header h1 {
      color: #ffffff;
      text-shadow: 3px 3px #deb887; }
    #locations header h3 {
      color: #deb887; }
  #locations .location-catalogue {
    width: 100%;
    flex: 1 0;
    display: flex;
    flex-flow: row wrap; }
    #locations .location-catalogue .location {
      flex: 1 0 300px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      position: relative; }
      #locations .location-catalogue .location .overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0; }
      #locations .location-catalogue .location h2 {
        color: #800000;
        font-size: 60px; }
      #locations .location-catalogue .location p {
        margin: 0px; }
      #locations .location-catalogue .location.school {
        background: url("/static/media/Centrum voor de kunsten.e7bf33ed.jpg") no-repeat center center;
        background-size: cover; }
        #locations .location-catalogue .location.school h2 {
          color: #ffffff; }
        #locations .location-catalogue .location.school p {
          color: #deb887;
          text-align: center; }
      #locations .location-catalogue .location.at-home {
        background: url(/static/media/road.73cb2be6.jpg) no-repeat center center;
        background-size: cover; }
      #locations .location-catalogue .location.hoverClass .overlay {
        opacity: 1; }
      #locations .location-catalogue .location.hoverClass h2 {
        text-shadow: 4px 4px #deb887;
        z-index: 2; }
      #locations .location-catalogue .location.hoverClass p {
        z-index: 2; }

#contact {
  min-height: 100vh;
  margin: 0px; }
  #contact header {
    text-align: center;
    margin: 100px 0px; }
    #contact header h2 {
      color: #ffffff; }
    #contact header p {
      margin: 0px; }
  #contact .contact-options {
    width: 100%;
    display: flex;
    flex-flow: row wrap; }
    #contact .contact-options div {
      flex: 1 0 300px;
      text-align: center;
      margin: 20px; }
      #contact .contact-options div i {
        color: #ffffff;
        font-size: 100px;
        margin-bottom: 35px;
        text-shadow: 4px 4px #deb887; }
      #contact .contact-options div h3 {
        color: #deb887; }

#sidenav-btn {
  position: fixed;
  z-index: 3;
  top: 0px;
  right: 0px;
  padding: 25px 40px;
  text-align: center;
  font-size: 40px;
  color: #ffffff; }
  #sidenav-btn:hover {
    text-shadow: 3px 3px #deb887; }
  #sidenav-btn:focus {
    outline: none; }

#sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #000000;
  overflow-x: hidden;
  padding-top: 100px;
  text-align: right; }
  #sidenav a {
    text-decoration: none;
    font-size: 40px;
    display: block;
    font-family: Oswald, sans-serif;
    padding: 10px 40px;
    color: #ffffff; }
    #sidenav a:hover {
      text-shadow: 3px 3px #deb887; }
  #sidenav.opened {
    width: 300px; }

@media screen and (max-width: 500px) {
  #sidenav.opened {
    width: 100%; } }

.logo {
  background: none;
  text-decoration: none;
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  padding: 30px 40px;
  text-align: center;
  font-family: Oswald, sans-serif;
  font-size: 30px;
  color: white;
  transition: 0.3s; }
  .logo:hover {
    color: #deb887; }

@media screen and (max-width: 650px) {
  .logo {
    font-size: 25px;
    padding: 37px 40px; } }

